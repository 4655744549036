// MonitorPlantHealth.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaArrowUp, FaArrowDown, FaHeartbeat, FaSeedling, FaChartLine, FaCalendarAlt } from 'react-icons/fa';
import NavigationBar from '../navbar/NavigationBar';
import bgImage from '../../images/bgImage.jpg';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import API_CONFIG from '../../config'; // Import the API base URL

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const MonitorPlantHealth = () => {
  const [growthRate, setGrowthRate] = useState(0);
  const [diseaseSpreadRate, setDiseaseSpreadRate] = useState(0);
  const [yieldPredictionRate, setYieldPredictionRate] = useState(0);
  const [predictedResults, setPredictedResults] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [diseaseTypes, setDiseaseTypes] = useState([]);

  const [resultsPage, setResultsPage] = useState(1);
  const [resultsPerPage] = useState(5); // Number of rows per page for results summary

  const [predictionsPage, setPredictionsPage] = useState(1);
  const [predictionsPerPage] = useState(5); // Number of rows per page for predictions table

  const [dateFilter, setDateFilter] = useState('');
  const [diseaseFilter, setDiseaseFilter] = useState('');

  // Fetch data from the backend API
  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await axios.get('http://localhost:8080/api/analytics/plantMetrics');
        setGrowthRate(response.data.growthRate);
        setDiseaseSpreadRate(response.data.diseaseSpreadRate);
        setYieldPredictionRate(response.data.yieldPredictionRate);
      } catch (error) {
        console.error('Error fetching plant metrics:', error);
      }
    };

    const fetchPredictedResults = async () => {
      try {
        const response = await axios.get(`${API_CONFIG.API_BASE_URL}/analytics/predictedResults`);
        setPredictedResults(response.data);
      } catch (error) {
        console.error('Error fetching predicted results:', error);
      }
    };

    const fetchPredictions = async () => {
      try {
        const response = await axios.get(`${API_CONFIG.API_BASE_URL}/predictions/getAllPredictions`);
        setPredictions(response.data);
      } catch (error) {
        console.error('Error fetching predictions:', error);
      }
    };

    const fetchDiseaseTypes = async () => {
      try {
        const response = await axios.get(`${API_CONFIG.API_BASE_URL}/predictions/allDiseaseTypes`);
        setDiseaseTypes(response.data);
      } catch (error) {
        console.error('Error fetching disease types:', error);
      }
    };

    fetchMetrics();
    fetchPredictedResults();
    fetchPredictions();
    fetchDiseaseTypes();
  }, []);

  const getStatusIndicator = (status) => {
    switch (status) {
      case 'Good':
        return <FaArrowUp className="text-green-500" />;
      case 'Moderate':
        return <FaArrowUp className="text-yellow-500" />;
      case 'Bad':
        return <FaArrowDown className="text-red-500" />;
      default:
        return <FaArrowUp className="text-gray-500" />;
    }
  };

  const handleDateFilterChange = (event) => setDateFilter(event.target.value);
  const handleDiseaseFilterChange = (event) => setDiseaseFilter(event.target.value);

  const filteredPredictions = predictions.filter((prediction) => {
    return (
      (!dateFilter || prediction.predictedDate === dateFilter) &&
      (!diseaseFilter || prediction.disease === diseaseFilter)
    );
  });

  // Pagination logic for results summary
  const resultsStartIndex = (resultsPage - 1) * resultsPerPage;
  const resultsPaginated = predictedResults.slice(resultsStartIndex, resultsStartIndex + resultsPerPage);

  // Pagination logic for predictions
  const predictionsStartIndex = (predictionsPage - 1) * predictionsPerPage;
  // const predictionsPaginated = predictions.slice(predictionsStartIndex, predictionsStartIndex + predictionsPerPage);
  const predictionsPaginated = filteredPredictions.slice(predictionsStartIndex, predictionsStartIndex + predictionsPerPage);

  const chartData = {
    labels: predictedResults.map((result) => result.date),
    datasets: [
      {
        label: 'Healthy Plants',
        data: predictedResults.map((result) => result.healthyCount),
        borderColor: 'green',
        backgroundColor: 'rgba(0, 128, 0, 0.2)',
      },
      {
        label: 'Diseased Plants',
        data: predictedResults.map((result) => result.diseasedCount),
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
      },
    ],
  };

  return (
    <div className="min-h-screen flex flex-col overflow-hidden">
      <NavigationBar />

      <div
        className="flex-grow overflow-hidden px-10 pt-4 hide-on-small"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="h-full flex flex-col bg-white bg-opacity-80 rounded-lg shadow p-4 mb-6">
          <div className="flex justify-between">
            <div className="w-1/3 px-4">
              <div className="bg-green-100 p-4 rounded-lg flex items-center">
                <FaSeedling className="text-green-500 text-3xl mr-4" />
                <div>
                  <p className="text-2xl font-bold">{growthRate}%</p>
                  <p className="text-gray-700">Growth Rate</p>
                </div>
              </div>
            </div>
            <div className="w-1/3 px-4">
              <div className="bg-red-100 p-4 rounded-lg flex items-center">
                <FaHeartbeat className="text-red-500 text-3xl mr-4" />
                <div>
                  <p className="text-2xl font-bold">{diseaseSpreadRate}%</p>
                  <p className="text-gray-700">Disease Spread Rate</p>
                </div>
              </div>
            </div>
            <div className="w-1/3 px-4">
              <div className="bg-blue-100 p-4 rounded-lg flex items-center">
                <FaChartLine className="text-blue-500 text-3xl mr-4" />
                <div>
                  <p className="text-2xl font-bold">{yieldPredictionRate}%</p>
                  <p className="text-gray-700">Yield Prediction Rate</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white bg-opacity-90 rounded-lg shadow p-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Results Summary Chart</h2>
          <div style={{ maxWidth: '600px', margin: '0 auto' }}>
            <Line data={chartData} />
          </div>
        </div>

        <div className="h-full flex flex-col bg-white bg-opacity-90 rounded-lg shadow p-6 mb-6">
          <div className="mb-2">
            <h2 className="text-xl font-bold mb-4">Results Summary</h2>
            <div className="overflow-auto">
              <table className="w-full table-auto bg-white rounded shadow">
                <thead>
                  <tr className="bg-gray-200 text-left">
                    <th className="px-4 py-2">Date</th>
                    <th className="px-4 py-2">Healthy Plant Count</th>
                    <th className="px-4 py-2">Diseased Plant Count</th>
                    <th className="px-4 py-2">Overall Status</th>
                  </tr>
                </thead>
                <tbody>
                  {resultsPaginated.map((result, index) => (
                    <tr key={index} className="border-b hover:bg-gray-100">
                      <td className="px-4 py-2">{result.date}</td>
                      <td className="px-4 py-2">{result.healthyCount}</td>
                      <td className="px-4 py-2">{result.diseasedCount}</td>
                      <td className="px-4 py-2 flex items-center">
                        {getStatusIndicator(result.status)}
                        <span className="ml-2">{result.status}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination controls */}
              <div className="flex justify-center items-center mt-4">
                <button
                  onClick={() => setResultsPage((prev) => Math.max(prev - 1, 1))}
                  disabled={resultsPage === 1}
                  className="bg-gray-300 px-4 mx-2 py-2 rounded hover:bg-gray-400"
                >
                  Previous
                </button>
                <span>
                  Page {resultsPage} of {Math.ceil(predictedResults.length / resultsPerPage)}
                </span>
                <button
                  onClick={() => setResultsPage((prev) => Math.min(prev + 1, Math.ceil(predictedResults.length / resultsPerPage)))}
                  disabled={resultsPage === Math.ceil(predictedResults.length / resultsPerPage)}
                  className="bg-gray-300 px-4 mx-2 py-2 rounded hover:bg-gray-400"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="h-full flex flex-col bg-white bg-opacity-90 rounded-lg shadow p-6 mb-6">
          <div className="flex-grow overflow-auto">
            <h2 className="text-xl font-bold mb-4">Prediction Results</h2>
            <div className="flex space-x-4 mb-4">
              <input
                type="date"
                value={dateFilter}
                onChange={handleDateFilterChange}
                className="border px-4 py-2 rounded"
              />
              <select
                value={diseaseFilter}
                onChange={handleDiseaseFilterChange}
                className="border px-4 py-2 rounded"
              >
                <option value="">All Diseases</option>
                {diseaseTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            <table className="w-full table-auto bg-white rounded shadow">
              <thead>
                <tr className="bg-gray-200 text-left">
                  <th className="px-4 py-2">Plant Pot Number</th>
                  <th className="px-4 py-2">Detected Disease</th>
                  <th className="px-4 py-2">Confidence Level</th>
                  <th className="px-4 py-2">Predicted Date</th>
                </tr>
              </thead>
              <tbody>
                {predictionsPaginated.map((prediction, index) => (
                  <tr key={index} className="border-b hover:bg-gray-100">
                    <td className="px-4 py-2">{prediction.potNumber}</td>
                    <td className={`px-4 py-2 ${prediction.disease === 'Healthy' ? 'text-green-500' : 'text-red-500'}`}>{prediction.disease}</td>
                    <td className="px-4 py-2">{prediction.confidence}%</td>
                    <td className="px-4 py-2">{prediction.predictedDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination controls */}
            <div className="flex justify-center items-center mt-4">
              <button
                onClick={() => setPredictionsPage((prev) => Math.max(prev - 1, 1))}
                disabled={predictionsPage === 1}
                className="bg-gray-300 px-4 mx-2 py-2 rounded hover:bg-gray-400"
              >
                Previous
              </button>
              <span>
                Page {predictionsPage} of {Math.ceil(predictions.length / predictionsPerPage)}
              </span>
              <button
                onClick={() => setPredictionsPage((prev) => Math.min(prev + 1, Math.ceil(predictions.length / predictionsPerPage)))}
                disabled={predictionsPage === Math.ceil(predictions.length / predictionsPerPage)}
                className="bg-gray-300 px-4 mx-2 py-2 rounded hover:bg-gray-400"
              >
                Next
              </button>
            </div>
          </div>

        </div>
      </div>


      {/* Mobile View */}
      <div
        className="show-on-small p-4 space-y-6 min-h-screen"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Results Summary */}
        <div className="bg-white bg-opacity-90 rounded-lg shadow p-4">
          <h2 className="text-lg font-bold mb-4 flex items-center">
            <FaChartLine className="mr-2 text-blue-500" />
            Results Summary
          </h2>
          {resultsPaginated.length > 0 ? (
            resultsPaginated.map((result, index) => (
              <div
                key={index}
                className="bg-gray-100 p-4 rounded-lg shadow mb-3 flex flex-col"
              >
                <div className="flex justify-between items-center mb-2">
                  <p className="text-sm font-semibold flex items-center">
                    <FaCalendarAlt className="mr-2 text-teal-500" /> {result.date}
                  </p>
                  <div className="flex items-center space-x-2">
                    {getStatusIndicator(result.status)}
                    <span className="text-sm font-semibold">{result.status}</span>
                  </div>
                </div>
                <p className="text-sm">
                  <strong>Healthy Plants : </strong> {result.healthyCount}
                </p>
                <p className="text-sm">
                  <strong>Diseased Plants : </strong> {result.diseasedCount}
                </p>
              </div>
            ))
          ) : (
            <div className="text-center text-red-600">No results found.</div>
          )}
          <div className="flex justify-between mt-4">
            <button
              onClick={() => setResultsPage((prev) => Math.max(prev - 1, 1))}
              disabled={resultsPage === 1}
              className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:bg-gray-200"
            >
              Previous
            </button>
            <span className="mt-2 text-sm">{`Page ${resultsPage} of ${Math.ceil(
              predictedResults.length / resultsPerPage
            )}`}</span>
            <button
              onClick={() =>
                setResultsPage((prev) =>
                  Math.min(prev + 1, Math.ceil(predictedResults.length / resultsPerPage))
                )
              }
              disabled={resultsPage === Math.ceil(predictedResults.length / resultsPerPage)}
              className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:bg-gray-200"
            >
              Next
            </button>
          </div>
        </div>

        {/* Prediction Results */}
        <div className="bg-white bg-opacity-90 rounded-lg shadow p-4">
          <h2 className="text-lg font-bold mb-4 flex items-center">
            <FaSeedling className="mr-2 text-green-500" />
            Prediction Results
          </h2>
          <div className="flex flex-col space-y-3 mb-4">
            <input
              type="date"
              value={dateFilter}
              onChange={handleDateFilterChange}
              className="border px-4 py-2 rounded"
            />
            <select
              value={diseaseFilter}
              onChange={handleDiseaseFilterChange}
              className="border px-4 py-2 rounded"
            >
              <option value="">All Diseases</option>
              {diseaseTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          {predictionsPaginated.length > 0 ? (
            predictionsPaginated.map((prediction, index) => (
              <div
                key={index}
                className="bg-gray-100 p-4 rounded-lg shadow mb-3 flex flex-col"
              >
                <div className="flex justify-between items-center mb-2">
                  <p className="text-sm font-semibold flex items-center">
                    <FaSeedling className="mr-2 text-green-500" /> {prediction.potNumber}
                  </p>
                  <p
                    className={`text-sm font-semibold ${
                      prediction.disease === 'Healthy' ? 'text-green-500' : 'text-red-500'
                    }`}
                  >
                    {prediction.disease}
                  </p>
                </div>
                <p className="text-sm flex items-center">
                  <FaChartLine className="mr-2 text-blue-500" />
                  <strong>Confidence : </strong> {prediction.confidence}%
                </p>
                <p className="text-sm flex items-center">
                  <FaCalendarAlt className="mr-2 text-teal-500" />
                  <strong>Date : </strong> {prediction.predictedDate}
                </p>
              </div>
            ))
          ) : (
            <div className="text-center text-red-600">No predictions found.</div>
          )}
          <div className="flex justify-between mt-4">
            <button
              onClick={() => setPredictionsPage((prev) => Math.max(prev - 1, 1))}
              disabled={predictionsPage === 1}
              className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:bg-gray-200"
            >
              Previous
            </button>
            <span className="mt-2 text-sm">{`Page ${predictionsPage} of ${Math.ceil(
              predictions.length / predictionsPerPage
            )}`}</span>
            <button
              onClick={() =>
                setPredictionsPage((prev) =>
                  Math.min(prev + 1, Math.ceil(predictions.length / predictionsPerPage))
                )
              }
              disabled={predictionsPage === Math.ceil(predictions.length / predictionsPerPage)}
              className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:bg-gray-200"
            >
              Next
            </button>
          </div>
        </div>
      </div>







    </div>
  );
};

export default MonitorPlantHealth;


