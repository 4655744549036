// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { FaSignOutAlt } from 'react-icons/fa';
// import { useNavigate } from 'react-router-dom';

// const NavigationBar = () => {
//   const [username, setUsername] = useState('');
//   const [role, setRole] = useState('');
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate(); // React Router's navigation hook

//   // Get current date
//   const currentDate = new Date().toLocaleDateString('en-US', {
//     // weekday: 'long',
//     year: 'numeric',
//     month: 'long',
//     day: 'numeric',
//   });

//   useEffect(() => {
//     // Fetch user details from session
//     axios.get('http://localhost:8080/api/session/user', { withCredentials: true })
//       .then(response => {
//         setUsername(response.data.username);
//         setRole(response.data.role);
//         setLoading(false);
//       })
//       .catch(error => {
//         console.error('Error fetching user session:', error);
//         setLoading(false);
//       });
//   }, []);

//   // Logout function
//   const handleLogout = () => {
//     axios.post('http://localhost:8080/api/logout')
//       .then(() => {
//         // Redirect to login page after logout
//         window.location.href = '/';
//       })
//       .catch((error) => {
//         console.error('Logout error:', error);
//       });
//   };

//   // Navigate based on role
//   const handleNavigate = () => {
//     if (role === 'Admin') {
//       navigate('/adminDashboard');
//     } else if (role === 'User') {
//       navigate('/workerDashboard');
//     }
//   };

//   return (
//     <div className="bg-white bg-opacity-20 py-3 px-8 shadow-md flex justify-between items-center h-12">
//       {/* App Name */}
//       <div className="text-xl font-bold text-black cursor-pointer"
//           onClick={handleNavigate} // Handle navigation when clicked
//       >
//         GreenGuard
//       </div>

//       {/* Right Section: Date, Username, Logout */}
//       <div className="flex items-center space-x-6">
//         {/* Current Date */}
//         <div className="text-gray-700">{currentDate}</div>

//         {/* Username */}
//         <div className="text-gray-700 font-semibold">Welcome, {username} ({role})</div>

//         {/* Logout Button */}
//         <button
//           onClick={handleLogout}
//           className="flex items-center bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 transition"
//         >
//           <FaSignOutAlt className="mr-2" />
//           Logout
//         </button>
//       </div>
//     </div>
//   );
// };

// export default NavigationBar;




// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { FaSignOutAlt } from 'react-icons/fa';
// import { useNavigate } from 'react-router-dom';

// const NavigationBar = () => {
//   const [username, setUsername] = useState('');
//   const [role, setRole] = useState('');
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate(); // React Router's navigation hook

//   // Get current date
//   const currentDate = new Date().toLocaleDateString('en-US', {
//     year: 'numeric',
//     month: 'long',
//     day: 'numeric',
//   });

//   useEffect(() => {
//     // Fetch user details from session
//     axios
//       .get('http://localhost:8080/api/session/user', { withCredentials: true })
//       .then((response) => {
//         setUsername(response.data.username);
//         setRole(response.data.role);
//       })
//       .catch((error) => {
//         console.error('Error fetching user session:', error);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   }, []);

//   // Logout function
//   const handleLogout = () => {
//     axios
//       .post('http://localhost:8080/api/logout')
//       .then(() => {
//         // Redirect to login page after logout
//         window.location.href = '/';
//       })
//       .catch((error) => {
//         console.error('Logout error:', error);
//       });
//   };

//   // Navigate based on role
//   const handleNavigate = () => {
//     if (role === 'Admin') {
//       navigate('/adminDashboard');
//     } else if (role === 'User') {
//       navigate('/workerDashboard');
//     }
//   };

//   return (
//     <div className="bg-white bg-opacity-80 py-3 px-4 sm:px-8 shadow-md flex justify-between items-center h-16">
//       {/* App Name */}
//       <div
//         className="text-lg sm:text-xl font-bold text-black cursor-pointer"
//         onClick={handleNavigate}
//       >
//         GreenGuard
//       </div>

//       {/* Right Section */}
//       <div className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-6">
//         {/* Current Date */}
//         <div className="text-sm sm:text-base text-gray-700">{currentDate}</div>

//         {/* Username */}
//         {loading ? (
//           <div className="text-sm sm:text-base text-gray-500">Loading...</div>
//         ) : username ? (
//           <div className="text-sm sm:text-base text-gray-700 font-semibold">
//             Welcome, {username} ({role})
//           </div>
//         ) : (
//           <div className="text-sm sm:text-base text-red-500">Session not found</div>
//         )}

//         {/* Logout Button */}
//         <button
//           onClick={handleLogout}
//           className="flex items-center bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 transition"
//         >
//           <FaSignOutAlt className="mr-2" />
//           Logout
//         </button>
//       </div>
//     </div>
//   );
// };

// export default NavigationBar;




// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { FaSignOutAlt, FaBars } from 'react-icons/fa';
// import { useNavigate } from 'react-router-dom';

// const NavigationBar = () => {
//   const [username, setUsername] = useState('');
//   const [role, setRole] = useState('');
//   const [loading, setLoading] = useState(true);
//   const [isMenuOpen, setIsMenuOpen] = useState(false); // Menu toggle state
//   const navigate = useNavigate(); // React Router's navigation hook

//   // Get current date
//   const currentDate = new Date().toLocaleDateString('en-US', {
//     year: 'numeric',
//     month: 'long',
//     day: 'numeric',
//   });

//   useEffect(() => {
//     // Fetch user details from session
//     axios
//       .get('http://localhost:8080/api/session/user', { withCredentials: true })
//       .then((response) => {
//         setUsername(response.data.username);
//         setRole(response.data.role);
//       })
//       .catch((error) => {
//         console.error('Error fetching user session:', error);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   }, []);

//   // Logout function
//   const handleLogout = () => {
//     axios
//       .post('http://localhost:8080/api/logout')
//       .then(() => {
//         // Redirect to login page after logout
//         window.location.href = '/';
//       })
//       .catch((error) => {
//         console.error('Logout error:', error);
//       });
//   };

//   // Navigate based on role
//   const handleNavigate = () => {
//     if (role === 'Admin') {
//       navigate('/adminDashboard');
//     } else if (role === 'User') {
//       navigate('/workerDashboard');
//     }
//   };

//   return (
//     <div className="bg-white bg-opacity-80 py-3 px-4 sm:px-8 shadow-md flex justify-between items-center h-16">
//       {/* App Name */}
//       <div
//         className="text-lg sm:text-xl font-bold text-black cursor-pointer"
//         onClick={handleNavigate}
//       >
//         GreenGuard
//       </div>

//       {/* Menu Icon for Smaller Devices */}
//       <div className="sm:hidden">
//         <button
//           onClick={() => setIsMenuOpen(!isMenuOpen)}
//           className="text-gray-700 focus:outline-none"
//         >
//           <FaBars className="text-xl" />
//         </button>
//       </div>

//       {/* Right Section for Larger Devices */}
//       <div className="hidden sm:flex flex-row sm:items-center space-x-6">
//         {/* Current Date */}
//         <div className="text-sm sm:text-base text-gray-700">{currentDate}</div>

//         {/* Username */}
//         {loading ? (
//           <div className="text-sm sm:text-base text-gray-500">Loading...</div>
//         ) : username ? (
//           <div className="text-sm sm:text-base text-gray-700 font-semibold">
//             Welcome, {username} ({role})
//           </div>
//         ) : (
//           <div className="text-sm sm:text-base text-red-500">Session not found</div>
//         )}

//         {/* Logout Button */}
//         <button
//           onClick={handleLogout}
//           className="flex items-center bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 transition"
//         >
//           <FaSignOutAlt className="mr-2" />
//           Logout
//         </button>
//       </div>

//       {/* Dropdown Menu for Smaller Devices */}
//       {isMenuOpen && (
//         <div className="absolute top-16 right-4 bg-white shadow-lg rounded-lg w-48 p-4 z-50">
//           <div className="text-gray-700 text-sm">{currentDate}</div>
//           {loading ? (
//             <div className="text-sm text-gray-500 mt-2">Loading...</div>
//           ) : username ? (
//             <div className="text-sm text-gray-700 font-semibold mt-2">
//               Welcome, {username} ({role})
//             </div>
//           ) : (
//             <div className="text-sm text-red-500 mt-2">Session not found</div>
//           )}
//           <button
//             onClick={handleLogout}
//             className="flex items-center bg-red-500 text-white px-3 py-2 rounded-lg hover:bg-red-600 transition mt-4 w-full"
//           >
//             <FaSignOutAlt className="mr-2" />
//             Logout
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default NavigationBar;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaSignOutAlt, FaBars, FaBell } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  FaInfoCircle,
  FaExclamationTriangle,
  FaTimesCircle,
} from 'react-icons/fa';
import API_CONFIG from '../../config'; // Import the API base URL

const NavigationBar = () => {
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Menu toggle state
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false); // Notification modal state
  const [allNotifications, setAllNotifications] = useState([]); // Notifications state
  const navigate = useNavigate(); // React Router's navigation hook

  // Get current date
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  useEffect(() => {
    // Fetch user details from session
    axios
      // .get('http://localhost:8080/api/session/user', { withCredentials: true })
      .get(`${API_CONFIG.API_BASE_URL}/session/user`, { withCredentials: true })
      .then((response) => {
        setUsername(response.data.username);
        setRole(response.data.role);
      })
      .catch((error) => {
        console.error('Error fetching user session:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Fetch all notifications
  const fetchAllNotifications = async () => {
    try {
      // const response = await axios.get('http://localhost:8080/api/notifications/user/all', {
      if (role === 'Admin') {
        const response = await axios.get(`${API_CONFIG.API_BASE_URL}/notifications/all`, {
          withCredentials: true,
        });
        setAllNotifications(response.data);
        setIsNotificationModalOpen(true); // Open modal
      } else if (role === 'User') {
        const response = await axios.get(`${API_CONFIG.API_BASE_URL}/notifications/user/all`, {
          withCredentials: true,
        });
        setAllNotifications(response.data);
        setIsNotificationModalOpen(true); // Open modal
      }
      // const response = await axios.get(`${API_CONFIG.API_BASE_URL}/notifications/user/all`, {
      //   withCredentials: true,
      // });
      // setAllNotifications(response.data);
      // setIsNotificationModalOpen(true); // Open modal
    } catch (error) {
      console.error('Error fetching all notifications:', error);
    }
  };

  // Function to get icon based on notification type
  const getNotificationIcon = (type) => {
    switch (type) {
      case 'info':
        return <FaInfoCircle className="text-blue-500 mr-2" />;
      case 'warning':
        return <FaExclamationTriangle className="text-yellow-500 mr-2" />;
      case 'danger':
        return <FaTimesCircle className="text-red-500 mr-2" />;
      case 'alert':
        return <FaBell className="text-green-500 mr-2" />;
      default:
        return <FaInfoCircle className="text-gray-500 mr-2" />;
    }
  };

  // Logout function
  const handleLogout = () => {
    axios
      // .post('http://localhost:8080/api/logout')
      .post(`${API_CONFIG.API_BASE_URL}/logout`)
      .then(() => {
        // Redirect to login page after logout
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('Logout error:', error);
      });
  };

  // Navigate based on role
  const handleNavigate = () => {
    if (role === 'Admin') {
      navigate('/adminDashboard');
    } else if (role === 'User') {
      navigate('/workerDashboard');
    }
  };

  return (
    <div className="bg-white bg-opacity-80 py-3 px-4 sm:px-8 shadow-md flex justify-between items-center h-13 relative">
      {/* App Name */}
      <div
        className="text-lg sm:text-xl font-bold text-black cursor-pointer"
        onClick={handleNavigate}
      >
        GreenGuard
      </div>

      {/* Menu Icon for Smaller Devices */}
      <div className="flex items-center sm:hidden">
        <button
          onClick={fetchAllNotifications}
          className="text-gray-700 mr-4 focus:outline-none relative"
        >
          <FaBell className="text-xl" />
          {/* Notification Indicator */}
          {allNotifications.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full px-1">
              {allNotifications.length}
            </span>
          )}
        </button>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-gray-700 focus:outline-none"
        >
          <FaBars className="text-xl" />
        </button>
      </div>

      {/* Right Section for Larger Devices */}
      <div className="hidden sm:flex flex-row sm:items-center space-x-6">
        {/* Current Date */}
        <div className="text-sm sm:text-base text-gray-700">{currentDate}</div>

        {/* Username */}
        {loading ? (
          <div className="text-sm sm:text-base text-gray-500">Loading...</div>
        ) : username ? (
          <div className="text-sm sm:text-base text-gray-700 font-semibold">
            Welcome, {username} ({role})
          </div>
        ) : (
          <div className="text-sm sm:text-base text-red-500">Session not found</div>
        )}

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="flex items-center bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 transition"
        >
          <FaSignOutAlt className="mr-2" />
          Logout
        </button>
      </div>

      {/* Dropdown Menu for Smaller Devices */}
      {isMenuOpen && (
        <div className="absolute top-16 right-4 bg-white shadow-lg rounded-lg w-48 p-4 z-50">
          <div className="text-gray-700 text-sm">{currentDate}</div>
          {loading ? (
            <div className="text-sm text-gray-500 mt-2">Loading...</div>
          ) : username ? (
            <div className="text-sm text-gray-700 font-semibold mt-2">
              Welcome, {username} ({role})
            </div>
          ) : (
            <div className="text-sm text-red-500 mt-2">Session not found</div>
          )}
          <button
            onClick={handleLogout}
            className="flex items-center bg-red-500 text-white px-3 py-2 rounded-lg hover:bg-red-600 transition mt-4 w-full"
          >
            <FaSignOutAlt className="mr-2" />
            Logout
          </button>
        </div>
      )}

      {/* All Notifications Modal */}
      {/* {isNotificationModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-1/3 max-h-[95vh] overflow-hidden">
            <div className="px-6 py-4 flex items-center justify-between border-b">
              <h3 className="text-xl font-bold">All Notifications</h3>
              <button
                onClick={() => setIsNotificationModalOpen(false)}
                className="text-gray-600 hover:text-gray-800"
              >
                ✕
              </button>
            </div>
            <div className="p-6 overflow-y-auto max-h-[60vh] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
              {allNotifications.length > 0 ? (
                <ul className="space-y-2">
                  {allNotifications.map((notification) => (
                    <li
                      key={notification.id}
                      className={`flex items-start p-3 rounded-lg shadow-sm ${
                        notification.status === 'info'
                          ? 'bg-blue-100'
                          : notification.status === 'warning'
                          ? 'bg-yellow-100'
                          : notification.status === 'danger'
                          ? 'bg-red-100'
                          : 'bg-green-100'
                      }`}
                    >
                      <div className="mr-3 text-xl mt-1 ml-1">
                        {notification.description}
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-700">
                          {notification.description}
                        </p>
                        <span className="text-xs text-gray-500">
                          {new Date(notification.ndate).toLocaleString()}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="text-center text-gray-500">No notifications available</div>
              )}
            </div>
            <div className="flex justify-end mt-6 px-6 py-4 border-t">
              <button
                onClick={() => setIsNotificationModalOpen(false)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )} */}


{isNotificationModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white rounded-lg shadow-lg w-full max-w-sm md:max-w-md lg:max-w-lg max-h-[90vh] overflow-hidden mx-4">
      {/* Modal Header */}
      <div className="px-4 py-3 flex items-center justify-between border-b">
        <h3 className="text-lg md:text-xl font-bold">All Notifications</h3>
        <button
          onClick={() => setIsNotificationModalOpen(false)}
          className="text-gray-600 hover:text-gray-800"
        >
          ✕
        </button>
      </div>
      
      {/* Modal Content */}
      <div className="p-4 overflow-y-auto max-h-[70vh] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        {allNotifications.length > 0 ? (
          <ul className="space-y-2">
            {allNotifications.map((notification) => (
              <li
                key={notification.id}
                className={`flex items-start p-3 rounded-lg shadow-sm ${
                  notification.status === 'info'
                    ? 'bg-blue-100'
                    : notification.status === 'warning'
                    ? 'bg-yellow-100'
                    : notification.status === 'danger'
                    ? 'bg-red-100'
                    : 'bg-green-100'
                }`}
              >
                <div className="mr-3 text-xl mt-1 ml-1">
                  {getNotificationIcon(notification.status)}
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">
                    {notification.description}
                  </p>
                  <span className="text-xs text-gray-500">
                    {new Date(notification.ndate).toLocaleString()}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center text-gray-500">No notifications available</div>
        )}
      </div>
      
      {/* Modal Footer */}
      <div className="flex justify-end mt-4 px-4 py-3 border-t">
        <button
          onClick={() => setIsNotificationModalOpen(false)}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default NavigationBar;
