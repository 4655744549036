import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrashAlt, FaPlus, FaSeedling, FaArrowLeft, FaArrowRight} from 'react-icons/fa';
import axios from 'axios';
import NavigationBar from '../navbar/NavigationBar';
import bgImage from '../../images/bgImage.jpg';
import API_CONFIG from '../../config'; // Import the API base URL
import Swal from 'sweetalert2';

const ManagePlant = () => {
  const [plots, setPlots] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8); // Number of items per page
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('add');
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [formData, setFormData] = useState({
    potNumber: '',
    plantType: '',
    userId: '',
  });

  // Fetch all plots from backend
  const fetchPlots = async () => {
    try {
      const response = await axios.get(`${API_CONFIG.API_BASE_URL}/plants/all`);
      setPlots(response.data);
    } catch (error) {
      console.error('Error fetching plots:', error);
    }
  };

  // useEffect to fetch plots on component mount
  useEffect(() => {
    fetchPlots();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Open modal for adding or editing
  const openModal = (mode, plot = null) => {
    setModalMode(mode);
    setIsModalOpen(true);
    if (mode === 'edit' && plot) {
      setSelectedPlot(plot);
      setFormData({
        potNumber: plot.potNumber,
        plantType: plot.plantType,
        userId: plot.userId,
      });
    } else {
      setFormData({ potNumber: '', plantType: '', userId: '' });
    }
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPlot(null);
  };

  // Handle form submission (Add or Update)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (modalMode === 'add') {
        // Add new plot
        await axios.post(`${API_CONFIG.API_BASE_URL}/plants/add`, formData);
      } else if (modalMode === 'edit') {
        // Update existing plot
        await axios.put(`${API_CONFIG.API_BASE_URL}/plants/update/${selectedPlot.id}`, formData);
      }
      fetchPlots(); // Refresh plots after submission
      closeModal();
    } catch (error) {
      console.error('Error saving plot:', error);
    }
  };

  // Delete plot
  // const deletePlot = async (plotId) => {
  //   const confirmed = window.confirm('Are you sure you want to delete this plot?');
  //   if (confirmed) {
  //     try {
  //       await axios.delete(`${API_CONFIG.API_BASE_URL}/plants/delete/${plotId}`);
  //       fetchPlots(); // Refresh plots after deletion
  //     } catch (error) {
  //       console.error('Error deleting plot:', error);
  //     }
  //   }
  // };
  const deletePlot = async (plotId) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "This action cannot be undone!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      });
  
      if (result.isConfirmed) {
        await axios.delete(`${API_CONFIG.API_BASE_URL}/plants/delete/${plotId}`);
        await fetchPlots(); // Refresh plots after deletion
  
        Swal.fire({
          title: 'Deleted!',
          text: 'The plot has been deleted successfully.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      }
    } catch (error) {
      console.error('Error deleting plot:', error);
  
      Swal.fire({
        title: 'Error!',
        text: 'Failed to delete the plot. Please try again.',
        icon: 'error',
        confirmButtonColor: '#d33',
      });
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(plots.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentPlots = plots.slice(startIndex, startIndex + itemsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className="min-h-screen flex flex-col overflow-hidden">
      {/* Navbar */}
      <NavigationBar />

      {/* Main Content */}
      <div
        className="flex-grow overflow-hidden px-10 pt-4 hide-on-small"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="h-full flex flex-col bg-white bg-opacity-50 rounded-lg shadow p-6">
          {/* Header */}
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold">Manage Plot Plants</h2>
            <button
              onClick={() => openModal('add')}
              className="flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              <FaPlus className="mr-2" />
              Add New Plot
            </button>
          </div>

          {/* Plot Table */}
          <div className="flex-grow overflow-auto">
            <table className="w-full table-auto bg-white rounded shadow">
              <thead>
                <tr className="bg-gray-200 text-left">
                  <th className="px-4 py-2">ID</th>
                  <th className="px-4 py-2">Pot Number</th>
                  <th className="px-4 py-2">Plant Type</th>
                  <th className="px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentPlots.map((plot) => (
                  <tr key={plot.id} className="border-b hover:bg-gray-100">
                    <td className="px-4 py-2">{plot.id}</td>
                    <td className="px-4 py-2">{plot.potNumber}</td>
                    <td className="px-4 py-2">{plot.plantType}</td>
                    <td className="px-4 py-2">
                      <button
                        onClick={() => openModal('edit', plot)}
                        className="text-yellow-500 hover:text-yellow-700 mr-4"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => deletePlot(plot.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-center items-center mt-4">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 mx-3 rounded ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white'}`}
            >
              Previous
            </button>
            <span>{`Page ${currentPage} of ${totalPages}`}</span>
            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 ml-3 rounded ${currentPage === totalPages ? 'bg-gray-300' : 'bg-blue-500 text-white'}`}
            >
              Next
            </button>
          </div>

          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg shadow-lg w-1/3">
                <div className="px-6 py-4 flex items-center justify-between border-b">
                  <h3 className="text-xl font-bold">
                    {modalMode === 'add' ? 'Add New Plot' : 'Edit Plot'}
                  </h3>
                  <button onClick={closeModal} className="text-gray-600 hover:text-gray-800">
                    ✕
                  </button>
                </div>
                <form onSubmit={handleSubmit} className="p-6">
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Pot Number</label>
                    <input
                      type="text"
                      name="potNumber"
                      value={formData.potNumber}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Plant Type</label>
                    <input
                      type="text"
                      name="plantType"
                      value={formData.plantType}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-700 mb-2">User ID</label>
                    <input
                      type="text"
                      name="userId"
                      value={formData.userId}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="px-4 py-2 mr-2 text-gray-700 hover:text-gray-900"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                    >
                      {modalMode === 'add' ? 'Add Plot' : 'Save Changes'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>


    {/* mobile view */}
    <div
      className="flex-grow overflow-hidden p-4 bg-gray-100 show-on-small"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="flex flex-col bg-white bg-opacity-70 rounded-lg shadow p-4">
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-bold">Manage Plot Plants</h2>
          <button
            onClick={() => openModal('add')}
            className="flex items-center px-3 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            <FaPlus className="text-sm" />
          </button>
        </div>

        {/* Plot Cards */}
        <div className="grid gap-4">
          {currentPlots.map((plot) => (
            <div
              key={plot.id}
              className="p-3 bg-white rounded-lg shadow hover:shadow-md transition duration-300"
            >
              <div className="flex justify-between items-center mt-1">
                <div className="mb-1 flex items-center space-x-3">
                <div className="bg-green-100 text-green-500 rounded-full p-2">
                  <FaSeedling className="text-lg" />
                </div>
                <div>
                  <h3 className="font-bold text-gray-800">Pot: {plot.potNumber}</h3>
                  <p className="text-sm text-gray-600">
                    {plot.plantType}
                  </p>
                </div>
              </div>
                <div className="flex space-x-3">
                  <button
                    onClick={() => openModal('edit', plot)}
                    className="text-yellow-500 hover:text-yellow-700"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => deletePlot(plot.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTrashAlt />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-md">
              <div className="px-4 py-3 flex items-center justify-between border-b">
                <h3 className="text-lg font-bold">
                  {modalMode === 'add' ? 'Add Plot' : 'Edit Plot'}
                </h3>
                <button onClick={closeModal} className="text-gray-600 hover:text-gray-800">
                  ✕
                </button>
              </div>
              <form onSubmit={handleSubmit} className="p-4">
                <div className="mb-3">
                  <label className="block text-gray-700 mb-1">Pot Number</label>
                  <input
                    type="text"
                    name="potNumber"
                    value={formData.potNumber}
                    onChange={handleInputChange}
                    required
                    className="w-full px-2 py-1 border rounded"
                  />
                </div>
                <div className="mb-3">
                  <label className="block text-gray-700 mb-1">Plant Type</label>
                  <input
                    type="text"
                    name="plantType"
                    value={formData.plantType}
                    onChange={handleInputChange}
                    required
                    className="w-full px-2 py-1 border rounded"
                  />
                </div>
                <div className="mb-3">
                  <label className="block text-gray-700 mb-1">User ID</label>
                  <input
                    type="text"
                    name="userId"
                    value={formData.userId}
                    onChange={handleInputChange}
                    required
                    className="w-full px-2 py-1 border rounded"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="px-3 py-1 mr-2 text-gray-700 hover:text-gray-900"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    {modalMode === 'add' ? 'Add Plot' : 'Save Changes'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4 bg-white p-4 rounded-lg shadow">
          <button
            onClick={prevPage}
            disabled={currentPage === 1}
            className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:bg-gray-200 flex items-center"
          >
            <FaArrowLeft className="mr-2" /> Previous
          </button>
          <span className="text-sm">{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            onClick={nextPage}
            disabled={currentPage === totalPages}
            className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:bg-gray-200 flex items-center"
          >
            Next <FaArrowRight className="ml-2" />
          </button>
        </div>
    </div>







    </div>
  );
};

export default ManagePlant;
