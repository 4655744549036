import React, { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";

const Check = () => {
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [facingMode, setFacingMode] = useState("user");
  const [error, setError] = useState(null);

  const captureImage = () => {
    const image = webcamRef.current.getScreenshot();
    setImageSrc(image);
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => setError(null))
      .catch((err) => setError(err.message));
  }, []);

  return (
    <div>
      <h1>Camera Capture</h1>
      {error ? (
        <p style={{ color: "red" }}>Error accessing camera: {error}</p>
      ) : !imageSrc ? (
        <div>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: facingMode,
            }}
            onUserMediaError={(err) => setError(err.message)}
          />
          <button onClick={captureImage}>Capture</button>
          <button onClick={switchCamera}>
            Switch to {facingMode === "user" ? "Back" : "Front"} Camera
          </button>
        </div>
      ) : (
        <div>
          <h2>Captured Image:</h2>
          <img src={imageSrc} alt="Captured" />
          <button onClick={() => setImageSrc(null)}>Retake</button>
        </div>
      )}
    </div>
  );
};

export default Check;
