import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios
import NavigationBar from '../navbar/NavigationBar';
import { FaEdit, FaTrashAlt, FaUserAlt, FaPlus } from 'react-icons/fa';
import bgImage from '../../images/bgImage.jpg';
import API_CONFIG from '../../config'; // Import the API base URL
import Swal from 'sweetalert2'; // Import SweetAlert2


const ManageUser = () => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact_number: '',
    role: '',
    status: '',
    password: '', // Added password field
  });

  // Fetch users from backend
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_CONFIG.API_BASE_URL}/users/all`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsers(); // Fetch users on component mount
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Open modal for adding or editing
  const openModal = (mode, user = null) => {
    setModalMode(mode);
    setIsModalOpen(true);
    if (mode === 'edit' && user) {
      setSelectedUser(user);
      setFormData({
        name: user.name,
        email: user.email,
        contact_number: user.contact_number,
        role: user.role,
        status: user.status,
        password: user.password, 
      });
    } else {
      setFormData({ name: '', email: '', contact_number: '', role: '', status: '', password: '' });
    }
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (modalMode === 'add') {
      try {
        const response = await axios.post(`${API_CONFIG.API_BASE_URL}/users/addUser`, formData)
        setUsers([...users, response.data]); // Update users list
      } catch (error) {
        console.error('Error adding user:', error);
      }
    } else if (modalMode === 'edit') {
      try {
        const response = await axios.put(`${API_CONFIG.API_BASE_URL}/users/updateUser/${selectedUser.id}`, formData);
        const updatedUsers = users.map((user) =>
          user.id === selectedUser.id ? response.data : user
        );
        setUsers(updatedUsers); // Update users list
      } catch (error) {
        console.error('Error updating user:', error);
      }
    }
    closeModal();
  };

  // Delete user
  // const deleteUser = async (userId) => {
  //   const confirmed = window.confirm('Are you sure you want to delete this user?');
  //   if (confirmed) {
  //     try {
  //       await axios.delete(`${API_CONFIG.API_BASE_URL}/users/deleteUser/${userId}`);
  //       const updatedUsers = users.filter((user) => user.id !== userId);
  //       setUsers(updatedUsers);
  //     } catch (error) {
  //       console.error('Error deleting user:', error);
  //     }
  //   }
  // };

  const deleteUser = async (userId) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "This action cannot be undone!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      });
  
      if (result.isConfirmed) {
        await axios.delete(`${API_CONFIG.API_BASE_URL}/users/deleteUser/${userId}`);
        await fetchUsers(); // Refresh after deletion
  
        Swal.fire({
          title: 'Deleted!',
          text: 'The user has been deleted successfully.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      }
    } catch (error) {
      console.error('Error deleting user:', error);
  
      Swal.fire({
        title: 'Error!',
        text: 'Failed to delete the user. Please try again.',
        icon: 'error',
        confirmButtonColor: '#d33',
      });
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <NavigationBar />

      {/* Main Content */}
      <div
        className="flex-grow overflow-hidden p-6 bg-gray-100 hide-on-small"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="h-full flex flex-col bg-white bg-opacity-50 rounded-lg shadow p-6">
          {/* Header */}
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold">Manage Users</h2>
            <button
              onClick={() => openModal('add')}
              className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              <FaPlus className="mr-2" />
              Add New User
            </button>
          </div>

          {/* User Table */}
          <div className="flex-grow overflow-auto">
            <table className="w-full table-auto bg-white rounded shadow">
              <thead>
                <tr className="bg-gray-200 text-left">
                  <th className="px-4 py-2">ID</th>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Email</th>
                  <th className="px-4 py-2">Contact Number</th>
                  <th className="px-4 py-2">Role</th>
                  <th className="px-4 py-2">Status</th>
                  <th className="px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id} className="border-b hover:bg-gray-100">
                    <td className="px-4 py-2">{user.id}</td>
                    <td className="px-4 py-2">{user.name}</td>
                    <td className="px-4 py-2">{user.email}</td>
                    <td className="px-4 py-2">{user.contact_number}</td>
                    <td className="px-4 py-2">{user.role}</td>
                    <td className="px-4 py-2">{user.status}</td>
                    <td className="px-4 py-2">
                      <button
                        onClick={() => openModal('edit', user)}
                        className="text-yellow-500 hover:text-yellow-700 mr-4"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => deleteUser(user.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg shadow-lg w-1/3">
                <div className="px-6 py-4 flex items-center justify-between border-b">
                  <h3 className="text-xl font-bold">
                    {modalMode === 'add' ? 'Add New User' : 'Edit User'}
                  </h3>
                  <button onClick={closeModal} className="text-gray-600 hover:text-gray-800">
                    ✕
                  </button>
                </div>
                <form onSubmit={handleSubmit} className="p-6">
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Role</label>
                    <select
                      name="role"
                      value={formData.role}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border rounded"
                    >
                      <option value="">Select Role</option>
                      <option value="Admin">Admin</option>
                      <option value="User">User</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Status</label>
                    <select
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border rounded"
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-700 mb-2">Contact Number</label>
                    <input
                      type="text"
                      name="contact_number"
                      value={formData.contact_number}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-700 mb-2">Password</label>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required={modalMode === 'add'} // Only required when adding new user
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="px-4 py-2 mr-2 text-gray-700 hover:text-gray-900"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      {modalMode === 'add' ? 'Add User' : 'Save Changes'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>



{/* mobile view */}
      <div className="show-on-small">
  <div
    className="flex-grow overflow-hidden p-4 bg-gray-100"
    style={{
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <div className="flex flex-col bg-white bg-opacity-50 rounded-lg shadow p-4">
      {/* Header */}
      <div className="flex flex-wrap items-center justify-between mb-4">
        <h2 className="text-lg font-bold">Manage Users</h2>
        <button
          onClick={() => openModal('add')}
          className="flex items-center px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          <FaPlus className="text-sm" />
        </button>
      </div>

      {/* User Cards */}
      {/* <div className="grid gap-4">
        {users.map((user) => (
          <div
            key={user.id}
            className="p-4 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
          >
            <div className="mb-2 flex items-center">
              <div className="ml-1">
                <h3 className="font-bold text-gray-800">{user.name}</h3>
                <p className="text-sm text-gray-600">{user.email}</p>
              </div>
            </div>
            <div className="flex justify-between items-center mt-3">
              <span
                className={`px-3 py-1 text-sm font-semibold rounded-full ${
                  user.role === 'Admin'
                    ? 'bg-red-100 text-red-600'
                    : 'bg-green-100 text-green-600'
                }`}
              >
                {user.role}
              </span>
              <div className="flex space-x-3">
                <button
                  onClick={() => openModal('edit', user)}
                  className="text-yellow-500 hover:text-yellow-700"
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => deleteUser(user.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <FaTrashAlt />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div> */}

      {/* User Cards */}
<div className="grid gap-4">
  {users.map((user) => (
    <div
      key={user.id}
      className="p-4 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
    >
      <div className="mb-2 flex items-center">
        <div className="bg-blue-100 text-blue-600 rounded-full p-2 mr-3">
          <FaUserAlt className="text-xl" />
        </div>
        <div>
          <h3 className="font-bold text-gray-800">{user.name}</h3>
          <p className="text-sm text-gray-600">{user.email}</p>
        </div>
      </div>
      <div className="flex justify-between items-center mt-3">
        <span
          className={`px-3 py-1 text-sm font-semibold rounded-full ${
            user.role === 'Admin'
              ? 'bg-red-100 text-red-600'
              : 'bg-green-100 text-green-600'
          }`}
        >
          {user.role}
        </span>
        <div className="flex space-x-3">
          <button
            onClick={() => openModal('edit', user)}
            className="text-yellow-500 hover:text-yellow-700"
          >
            <FaEdit />
          </button>
          <button
            onClick={() => deleteUser(user.id)}
            className="text-red-500 hover:text-red-700"
          >
            <FaTrashAlt />
          </button>
        </div>
      </div>
    </div>
  ))}
</div>


      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-md">
            <div className="px-4 py-3 flex items-center justify-between border-b">
              <h3 className="text-lg font-bold">
                {modalMode === 'add' ? 'Add User' : 'Edit User'}
              </h3>
              <button onClick={closeModal} className="text-gray-600 hover:text-gray-800">
                ✕
              </button>
            </div>
            <form onSubmit={handleSubmit} className="p-4">
              <div className="mb-3">
                <label className="block text-gray-700 mb-1">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="w-full px-2 py-1 border rounded"
                />
              </div>
              <div className="mb-3">
                <label className="block text-gray-700 mb-1">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="w-full px-2 py-1 border rounded"
                />
              </div>
              <div className="mb-3">
                <label className="block text-gray-700 mb-1">Role</label>
                <select
                  name="role"
                  value={formData.role}
                  onChange={handleInputChange}
                  required
                  className="w-full px-2 py-1 border rounded"
                >
                  <option value="">Select Role</option>
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="block text-gray-700 mb-1">Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required={modalMode === 'add'}
                  className="w-full px-2 py-1 border rounded"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={closeModal}
                  className="px-3 py-1 mr-2 text-gray-700 hover:text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  {modalMode === 'add' ? 'Add User' : 'Save Changes'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  </div>
</div>









    </div>
  );
};

export default ManageUser;
