import React, { useState, useEffect } from 'react';
import axios from 'axios';
import bgImage from '../../images/bgImage.jpg';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import NavigationBar from '../navbar/NavigationBar';
import API_CONFIG from '../../config'; // Import the API base URL

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PredictionResult = () => {
  const [isDaily, setIsDaily] = useState(true);
  const [predictions, setPredictions] = useState([]);
  const [diseaseTypes, setDiseaseTypes] = useState([]);
  const [filterDate, setFilterDate] = useState('');
  const [filterMonth, setFilterMonth] = useState('');
  const [filterDisease, setFilterDisease] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Fetch predictions and disease types
  useEffect(() => {
    const fetchPredictions = async () => {
      try {
        const response = await axios.get(`${API_CONFIG.API_BASE_URL}/predictions/getAllPredictions`);
        setPredictions(response.data);
      } catch (error) {
        console.error("Error fetching predictions:", error);
      }
    };

    const fetchDiseaseTypes = async () => {
      try {
        const response = await axios.get(`${API_CONFIG.API_BASE_URL}/predictions/allDiseaseTypes`);
        setDiseaseTypes(response.data);
      } catch (error) {
        console.error("Error fetching disease types:", error);
      }
    };

    fetchPredictions();
    fetchDiseaseTypes();
  }, []);

  // Filtering logic for daily and monthly views
  const filteredPredictions = predictions.filter(prediction => {
    const predictionDate = new Date(prediction.predictedDate);
    
    // Filter by disease
    if (filterDisease && prediction.disease !== filterDisease) {
      return false;
    }

    // Daily filter
    if (isDaily && filterDate) {
      return prediction.predictedDate === filterDate;
    }
    
    // Monthly filter
    if (!isDaily && filterMonth) {
      const month = String(predictionDate.getMonth() + 1).padStart(2, '0'); // "MM" format
      const year = predictionDate.getFullYear();
      return `${year}-${month}` === filterMonth;
    }

    return true;
  });

  // Aggregate disease counts for the filtered predictions
  const diseaseCounts = filteredPredictions.reduce((counts, pred) => {
    counts[pred.disease] = (counts[pred.disease] || 0) + 1;
    return counts;
  }, {});

  const diseaseCountData = {
    labels: Object.keys(diseaseCounts),
    datasets: [{
      label: isDaily ? 'Daily Disease Count' : 'Monthly Disease Count',
      data: Object.values(diseaseCounts),
      backgroundColor: ['#f87171', '#34d399', '#60a5fa', '#fbbf24', '#a78bfa'],
    }]
  };

  // Pagination calculations
  const totalPages = Math.ceil(filteredPredictions.length / itemsPerPage);
  const currentItems = filteredPredictions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Pagination controls
  const nextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
  const prevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));

  return (
    <div className="min-h-screen flex flex-col">
      <NavigationBar />
      <div className="flex-grow overflow-auto hide-on-small" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="px-20 py-8">
          {/* Disease Count Chart Section */}
          <div className="grid grid-cols-1 gap-4 mb-8">
            <div className="bg-white bg-opacity-90 rounded-lg shadow p-4 flex flex-col">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Disease Count Chart</h2>
                <div>
                  <button onClick={() => { setIsDaily(true); setFilterMonth(''); }} className={`px-3 py-1 rounded-l-lg ${isDaily ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                    Daily
                  </button>
                  <button onClick={() => { setIsDaily(false); setFilterDate(''); }} className={`px-3 py-1 rounded-r-lg ${!isDaily ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                    Monthly
                  </button>
                </div>
              </div>
              <div className="flex space-x-4 mb-4 justify-end">
                {isDaily ? (
                  <input
                    type="date"
                    className="border rounded px-3 py-2"
                    value={filterDate}
                    onChange={(e) => setFilterDate(e.target.value)}
                  />
                ) : (
                  <input
                    type="month"
                    className="border rounded px-3 py-2"
                    value={filterMonth}
                    onChange={(e) => setFilterMonth(e.target.value)}
                  />
                )}
              </div>
              <div className="flex-1 mt-4">
                <Bar data={diseaseCountData} options={{ maintainAspectRatio: false }} />
              </div>
            </div>
          </div>

          {/* Disease Prediction Results Table Section */}
          <div className="bg-white bg-opacity-70 rounded-lg shadow p-4">
            <h2 className="text-xl font-bold mb-4">Disease Prediction Results</h2>
            
            {/* Filters */}
            <div className="flex space-x-4 mb-4">
              <select
                className="border rounded px-3 py-2"
                value={filterDisease}
                onChange={(e) => setFilterDisease(e.target.value)}
              >
                <option value="">Filter by Disease</option>
                {diseaseTypes.map((disease, index) => (
                  <option key={index} value={disease}>{disease}</option>
                ))}
              </select>
            </div>

            {/* Prediction Table */}
            <div className="overflow-auto">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b text-left">Plant Pot Number</th>
                    <th className="py-2 px-4 border-b text-left">Detected Disease</th>
                    <th className="py-2 px-4 border-b text-left">Confidence Level</th>
                    <th className="py-2 px-4 border-b text-left">Predicted Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((result, index) => (
                    <tr key={index}>
                      <td className="py-2 px-4 border-b">{result.potNumber}</td>
                      <td className={`py-2 px-4 border-b ${result.disease === 'Healthy' ? 'text-green-500' : 'text-red-500'}`}>{result.disease}</td>
                      <td className="py-2 px-4 border-b">{result.confidence}%</td>
                      <td className="py-2 px-4 border-b">{result.predictedDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-center items-center mt-4">
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className="mx-2 px-4 py-2 rounded bg-gray-300 hover:bg-gray-400"
              >
                Previous
              </button>
              <span className="mx-2">{`Page ${currentPage} of ${totalPages}`}</span>
              <button
                onClick={nextPage}
                disabled={currentPage === totalPages}
                className="mx-2 px-4 py-2 rounded bg-gray-300 hover:bg-gray-400"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>


      {/* Mobile View */}
      {/* <div className="flex-grow overflow-auto show-on-small bg-white bg-opacity-70 p-4" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <h2 className="text-lg font-bold mb-4">Mobile View: Results</h2>
        <div>
          {currentItems.map((item, index) => (
            <div key={index} className="border rounded-lg p-3 mb-3 bg-white shadow">
              <p><strong>Pot:</strong> {item.potNumber}</p>
              <p><strong>Disease:</strong> <span className={item.disease === 'Healthy' ? 'text-green-500' : 'text-red-500'}>{item.disease}</span></p>
              <p><strong>Confidence:</strong> {item.confidence}%</p>
              <p><strong>Date:</strong> {item.predictedDate}</p>
            </div>
          ))}
        </div>
        <div className="flex justify-between mt-4">
          <button onClick={prevPage} disabled={currentPage === 1} className="bg-gray-300 p-2 rounded">Previous</button>
          <span>{`${currentPage} / ${totalPages}`}</span>
          <button onClick={nextPage} disabled={currentPage === totalPages} className="bg-gray-300 p-2 rounded">Next</button>
        </div>
      </div> */}
      <div
        className="flex-grow overflow-auto show-on-small bg-gray-100"
        style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      >
        {/* Header Section */}
        <div className="sticky top-0 bg-white shadow-md px-4 py-2">
          <h2 className="text-lg font-bold text-center">Disease Prediction Results</h2>
          <button
            onClick={() => setIsDaily(!isDaily)}
            className="w-full px-3 py-2 bg-blue-500 text-white rounded-md mt-2"
          >
            Toggle {isDaily ? 'Monthly' : 'Daily'} View
          </button>
        </div>

        {/* Filters */}
        <div className="bg-white border rounded px-2 py-2 mt-2 mx-2">
          <div className="flex justify-between">
            {isDaily ? (
              <input
                type="date"
                className="border rounded px-3 py-2 w-full"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
              />
            ) : (
              <input
                type="month"
                className="border rounded px-3 py-2 w-full"
                value={filterMonth}
                onChange={(e) => setFilterMonth(e.target.value)}
              />
            )}
          </div>
          <select
            className="border rounded px-3 py-2 w-full mt-2"
            value={filterDisease}
            onChange={(e) => setFilterDisease(e.target.value)}
          >
            <option value="">Filter by Disease</option>
            {diseaseTypes.map((disease, index) => (
              <option key={index} value={disease}>
                {disease}
              </option>
            ))}
          </select>
        </div>

        {/* Chart */}
        <div className="bg-white mx-2 p-4 shadow rounded-md">
          <div className="flex justify-center overflow-x-auto">
            <Bar
              data={diseaseCountData}
              options={{
                maintainAspectRatio: false,
                plugins: { legend: { display: false } },
              }}
              height={200}
            />
          </div>
        </div>

        {/* Results List */}
        <div className="px-4 py-2">
          {currentItems.length === 0 ? (
            <p className="text-center text-lightWhite">No results found</p>
          ) : (
            currentItems.map((item, index) => (
              <div
                key={index}
                className="bg-white shadow rounded-md p-4 mb-3"
              >
                <p className="font-bold">Pot: {item.potNumber}</p>
                <p>
                  <strong>Disease:</strong>{' '}
                  <span
                    className={item.disease === 'Healthy' ? 'text-green-500' : 'text-red-500'}
                  >
                    {item.disease}
                  </span>
                </p>
                <p>
                  <strong>Date:</strong> {item.predictedDate}
                </p>
              </div>
            ))
          )}
        </div>

        {/* Sticky Pagination */}
        <div className="sticky bottom-0 bg-white shadow-md py-2 px-4 flex justify-between items-center">
          <button
            onClick={prevPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded ${
              currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white'
            }`}
          >
            Previous
          </button>
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            onClick={nextPage}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded ${
              currentPage === totalPages ? 'bg-gray-300' : 'bg-blue-500 text-white'
            }`}
          >
            Next
          </button>
        </div>
      </div>





    </div>
  );
};

export default PredictionResult;
