import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/login/Login';
import AdminDashboard from './components/adminDashboard/AdminDashboard';
import ManageUser from './components/manageUser/ManageUser';
import ManagePlant from './components/managePlant/ManagePlant';
import ManageTasks from './components/manageTask/ManageTask';
import MonitorPlantHealth from './components/monitorPlantHealth/MonitorPlantHealth';
import WorkerDashboard from './components/workerDashboard/WorkerDashboard';
import PredictImage from './components/predictImage/PredictImage';
import PredictionResult from './components/predictionResult/PredictionResult';
import Check from './components/camera/Check';


function App() {
  return (
    
    <Router>
      <Routes>
        {/* <Route path="/" element={<Login />}/> */}
        <Route path="/" element={<PredictImage />}/>
        <Route path="/adminDashboard" element={<AdminDashboard />}/>
        <Route path="/manageUser" element={<ManageUser/>}/>
        <Route path="/managePlant" element={<ManagePlant/>}/>
        <Route path="/manageTask" element={<ManageTasks/>}/>
        <Route path="/monitorPlantHealth" element={<MonitorPlantHealth/>}/>

        <Route path="/workerDashboard" element={<WorkerDashboard/>}/>
        <Route path="/predictImage" element={<PredictImage/>}/>
        <Route path="/predictionResult" element={<PredictionResult/>} />

        <Route path="/camera" element={<Check/>} />
      </Routes>
    </Router>

  )
}

export default App;
