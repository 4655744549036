// // config.js

// const isLocalhost = window.location.hostname === 'localhost';

// const API_BASE_URL = isLocalhost
//   ? 'http://localhost:8080/api' // Localhost API
//   : 'http://192.168.1.87:8080/api'; // Public API (Laptop IP)


// const FLASK_BASE_URL = isLocalhost
// ? 'http://127.0.0.1:5000' // Localhost Flask API
// : 'http://192.168.1.87:5000'; // Public Flask API


// // Export the configuration
// const API_CONFIG = {
//     API_BASE_URL,
//     FLASK_BASE_URL,
// };

// export default API_CONFIG;

const isLocalhost = window.location.hostname === 'localhost';

const API_BASE_URL = isLocalhost
  ? 'http://localhost:8080/api' // Localhost API
  : 'http://192.168.1.87:8080/api'; // Public API 
  // : 'http://192.168.5.216:8080/api'; // Public API 
  

const FLASK_BASE_URL = isLocalhost
  ? 'http://127.0.0.1:5000' // Localhost Flask API
  : 'http://192.168.1.87:5000'; // Public Flask API
  // : 'http://192.168.5.216:5000'; // Public Flask API


// Export the configuration
const API_CONFIG = {
  API_BASE_URL,
  FLASK_BASE_URL,
};

export default API_CONFIG;
